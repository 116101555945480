"use client";

import { BellNotificationIcon } from "icons";
import React, { useState, useEffect } from "react";
import { Button, DialogTrigger, OverlayArrow } from "react-aria-components";

import { NotificationListing } from "@/components/layout/NotificationListing";
import Popover from "@/components/Notifications/Popover";
import { useNotificationGetUserUnreadNotificationsCount } from "@/hooks/api/generated";

export function NotificationBox(): JSX.Element {
  const [notificationCount, setNotificationCount] = useState(0);
  const { data: unreadNotificationsCount } =
    useNotificationGetUserUnreadNotificationsCount();

  useEffect(() => {
    const getUnreadNotificationsCount = (): void => {
      if (unreadNotificationsCount !== undefined) {
        setNotificationCount(unreadNotificationsCount);
      }
    };
    getUnreadNotificationsCount();
  }, [unreadNotificationsCount]);

  const handlePopoverOpen = (isOpen: boolean): void => {
    if (isOpen) {
      setNotificationCount(0);
    }
  };

  return (
    <DialogTrigger onOpenChange={handlePopoverOpen}>
      <Button
        aria-label="Notifications"
        className="inline-flex items-center justify-center rounded-md border border-white/20 px-3.5 py-2 font-medium text-white hover:bg-opacity-30 pressed:bg-opacity-40 transition-colors cursor-default outline-none focus-visible:ring-2 focus-visible:ring-white/75"
      >
        <BellNotificationIcon className="h-5 w-5" aria-hidden="true" />
        {notificationCount > 0 && (
          <span className="absolute inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-primary rounded-full">
            {notificationCount}
          </span>
        )}
      </Button>
      <Popover
        className="w-[95vw] md:w-[30rem] placement-bottom:mt-2 placement-top:mb-2 group rounded-lg drop-shadow-lg ring-1 ring-black/10 bg-white"
        crossOffset={-200}
      >
        <OverlayArrow>
          <svg
            viewBox="0 0 12 12"
            className="block fill-white group-placement-bottom:rotate-180 w-4 h-4 drop-shadow-lg"
          >
            <path d="M0 0L6 6L12 0" />
          </svg>
        </OverlayArrow>
        <NotificationListing />
      </Popover>
    </DialogTrigger>
  );
}
