export enum ProfileTabsEnum {
  HOME = "home",
  ORGANIZATION_PROFILE = "organization-profile",
  MY_SAVED_JOBS = "saved-jobs",
  MY_CONNECTIONS = "connections",
  MY_MESSAGES = "messages",
  MyDocuments = "documents",
  SETTINGS = "settings",
}

export enum ProfileSettingsTabsEnum {
  MY_ACCOUNT = "my-account",
  NOTIFICATIONS = "notifications",
  NEWSLETTER = "newsletter",
  BLOCK_MEMBERS = "block-members",
}
