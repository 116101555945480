import {
  setCookie,
  getCookie,
  CookieValueTypes,
  deleteCookie,
} from "cookies-next";

import { CookieKeys } from "@/enums/CookieKeys.enum";

interface ICookie {
  key: CookieKeys;
  value: CookieValueTypes;
  maxAge?: number;
}

interface IUseCookies {
  setCookie: ({ key, value, maxAge }: ICookie) => void;
  getCookie: (key: CookieKeys) => string | undefined;
  removeCookie: (key: CookieKeys) => void;
}

export const useCookies = (): IUseCookies => {
  const oneMonthInSeconds = 30 * 24 * 60 * 60;

  const handleSetCookie = ({
    key,
    value,
    maxAge = oneMonthInSeconds,
  }: ICookie): void => {
    setCookie(key, value, { maxAge });
  };

  const handleGetCookie = (key: CookieKeys): string | undefined =>
    getCookie(key);

  const handleRemoveCookie = (key: CookieKeys): void => {
    deleteCookie(key);
  };

  return {
    setCookie: handleSetCookie,
    getCookie: handleGetCookie,
    removeCookie: handleRemoveCookie,
  };
};
