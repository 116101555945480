import {
  initialize,
  openSession,
  changeUser,
  getUser,
  wipeData,
  logCustomEvent,
  requestPushPermission,
} from "@braze/web-sdk";
import { getCookie, setCookie } from "cookies-next";
import { v4 as uuidv4 } from "uuid";

import { LoginResponse } from "@/hooks/api/generated";

let isInitialized = false;

export const initializeBraze = (authData?: LoginResponse): void => {
  if (typeof window !== "undefined") {
    initializeSDK();
    if (authData?.uuid) {
      changeUser(authData.uuid);
    } else {
      if (!getCookie("anonymous_user")) {
        setCookie("anonymous_user", uuidv4());
      }

      const anonymousUser = getCookie("anonymous_user");
      if (typeof anonymousUser === "string") {
        changeUser(anonymousUser);
      }
    }
    openSession();
  }
};

export const logBrazeEvents = (eventName: string, eventProperty = {}): void => {
  if (typeof window !== "undefined") {
    logCustomEvent(eventName, eventProperty);
  }
};

export const initializeSDK = (): void => {
  try {
    if (typeof window !== "undefined" && !isInitialized) {
      initialize(`${process.env.BRAZE_API_KEY}`, {
        baseUrl: `${process.env.BRAZE_BASE_URL}`, // Use the correct endpoint for your region
        enableLogging: process.env.NODE_ENV === "development", // Optional: enable logging for debugging
        sessionTimeoutInSeconds: 600,
        serviceWorkerLocation: "/service-worker.js",
      });
      isInitialized = true;
      requestPushPermission();
    }
  } catch (error) {
    /* empty */
  }
};

export const brazeWipeData = (): void => {
  if (typeof window !== "undefined") {
    wipeData();
  }
};

enum Genders {
  Male = "m",
  Female = "f",
  Other = "o",
  NonBinary = "u",
  None = "n",
}

export const Pronoun = {
  None: Genders.None,
  "She/Her": Genders.Female,
  "He/Him": Genders.Male,
  "They/Them": Genders.NonBinary,
  Other: Genders.Other,
} as const;

export const changeBrazeUser = (userData: LoginResponse): void => {
  if (typeof window !== "undefined") {
    wipeData();
    initializeSDK();
    const user = getUser();
    user?.setEmail(userData.email);
    user?.setFirstName(userData.first_name);
    user?.setLastName(userData.last_name);
    if (userData?.userTaxonomy.contact_phone) {
      user?.setPhoneNumber(userData?.userTaxonomy.contact_phone);
    }
    user?.setGender(Pronoun[userData?.pronoun] as Genders);
    user?.setHomeCity(userData?.location);
    user?.setCountry(userData?.country);
    if (userData.userTaxonomy.job_title) {
      user?.setCustomUserAttribute(
        "job_title",
        userData.userTaxonomy.job_title
      );
    }
    if (userData.userTaxonomy.organization) {
      user?.setCustomUserAttribute(
        "organisation_id",
        userData.userTaxonomy.organization.id
      );
    }
    if (userData.userTaxonomy.organization) {
      user?.setCustomUserAttribute(
        "organisation_name",
        userData.userTaxonomy.organization.name
      );
    }
    if (userData.createdAt) {
      user?.setCustomUserAttribute("created_at", userData.createdAt);
    }
    user?.setCustomUserAttribute(
      "display_name",
      `${userData.first_name} ${userData.last_name}`
    );
    if (userData.userTaxonomy.is_mentor) {
      user?.setCustomUserAttribute(
        "is_mentor",
        userData.userTaxonomy.is_mentor
      );
    }
    user?.setCustomUserAttribute("is_mentee", userData.userTaxonomy.is_mentee);
    if (userData.userTaxonomy.mentor_speciality) {
      user?.setCustomUserAttribute(
        "mentor_speciality",
        userData.userTaxonomy.mentor_speciality
      );
    }
    if (userData.userTaxonomy.mentee_speciality) {
      user?.setCustomUserAttribute(
        "mentee_speciality",
        userData.userTaxonomy.mentee_speciality
      );
    }
    if (userData?.location) {
      user?.setCustomUserAttribute("city_on_profile", userData?.location);
    }
    if (userData?.country) {
      user?.setCustomUserAttribute("country_on_profile", userData?.country);
    }

    changeUser(userData.uuid);
    openSession();
  }
};
