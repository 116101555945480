import footerTranslations from "@locales/en/footer.json";

export type SectionName = keyof typeof footerTranslations.sections;
export type NavigationName =
  keyof typeof footerTranslations.section_navigation_items;

export type INavigationSection = {
  [section in SectionName]: {
    name: NavigationName;
    href: string;
  }[];
};

export const NAVIGATION_SECTIONS: INavigationSection = {
  about_us: [
    { name: "about_myGwork", href: "/about-us" },
    { name: "organizations", href: "/organizations" },
    { name: "contact_us", href: "/about-us/contact-us" },
    { name: "corporate_solutions", href: "/about-us/corporate-solutions" },
  ],
  for_members: [
    { name: "jobs", href: "/jobs" },
    { name: "members", href: "/members" },
    { name: "events", href: "/events" },
    { name: "news", href: "/news" },
  ],
  data_and_privacy: [
    { name: "privacy_policy", href: "/privacy-policy" },
    { name: "accessibility_policy", href: "/accessibility-policy" },
    { name: "cookies_policy", href: "/cookies-policy" },
    { name: "terms_of_use", href: "/terms-of-use" },
    { name: "dsar_form", href: "/dsar-form" },
  ],
};
