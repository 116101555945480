"use client";

import { Disclosure } from "@headlessui/react";
import classNames from "classnames";
import {
  HamburgerIcon,
  MyGworkLogoIcon,
  MyGworkLogoMobileIcon,
  XMarkIcon,
} from "icons";
import { useState } from "react";
import { Button as AriaButton } from "react-aria-components";
import { useTranslation } from "react-i18next";
import { Button, Link, Popover, Text } from "ui-components";

import { NotificationBox } from "@/components/layout/NotificationBox";
import { useAuth } from "@/src/hooks/useAuth";
import { useNavigation } from "@/src/hooks/useNavigation";

import { ProfilePicture } from "../ProfilePicture/ProfilePicture";

export default function Navbar(): JSX.Element {
  const [isShowingUserMenu, setIsShowingUserMenu] = useState(false);
  const { t } = useTranslation(["nav", "validation"]);
  const { navigation, userDropdownItems } = useNavigation();
  const { isAuthenticated, authData, signOut } = useAuth();

  return (
    <Disclosure as="nav" className="bg-white">
      {({ open }) => (
        <>
          <div className="max-w-[1440px] mx-auto px-5 lg:px-20">
            <div className="relative flex h-12 lg:h-[86px] items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button */}
                {!open && (
                  <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="absolute -inset-0.5" />
                    <span className="sr-only">Open main menu</span>
                    <HamburgerIcon
                      className="block h-5 w-4"
                      aria-hidden="true"
                    />
                  </Disclosure.Button>
                )}
              </div>
              <div className="flex flex-1 items-center justify-center lg:items-stretch lg:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <Link
                    aria-label="Home Icons, Will Navigate to Main Page"
                    className="bg-transparent m-0 p-0 outline-none "
                    href={
                      isAuthenticated
                        ? authData?.isLegacyOrganizationAdmin
                          ? "/profile/organization-profile"
                          : "/profile/home"
                        : "/"
                    }
                  >
                    <>
                      <MyGworkLogoMobileIcon className="block lg:hidden w-[90px] h-[21px]" />
                      <MyGworkLogoIcon className="hidden lg:block w-[59px] h-[59px]" />
                    </>
                  </Link>
                </div>
                <div className="hidden lg:ml-6 lg:flex items-center">
                  <div className="flex space-x-6">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          item.current ? "border-b-2 border-primary" : "",
                          "py-2"
                        )}
                        aria-current={item.current ? "page" : undefined}
                        decoration={false}
                        target={
                          item.href.startsWith("http") ? "_blank" : undefined
                        }
                        isAbsolute={item.href.startsWith("http")}
                        rel={
                          item.href.startsWith("http")
                            ? "noopener noreferrer"
                            : undefined
                        }
                      >
                        {t(item.name)}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 lg:static lg:inset-auto lg:ml-6 lg:pr-0">
                {open ? (
                  <Disclosure.Button>
                    <XMarkIcon className="block h-3 w-3" aria-hidden="true" />
                  </Disclosure.Button>
                ) : (
                  <>
                    <div
                      className={
                        isAuthenticated
                          ? "flex items-center justify-center space-x-3"
                          : "hidden"
                      }
                    >
                      {isAuthenticated && <NotificationBox />}
                      <Popover
                        isOpen={isShowingUserMenu}
                        onOpenChange={setIsShowingUserMenu}
                      >
                        <Popover.Trigger>
                          <ProfilePicture size="xs" />
                        </Popover.Trigger>
                        <Popover.Content
                          placement="bottom right"
                          className="w-72"
                        >
                          <div className="w-full flex flex-col items-center justify-center gap-y-2.5 p-6">
                            <ProfilePicture size="md" />
                            <Text>{authData?.email}</Text>
                            {userDropdownItems.map((item) => (
                              <Link
                                href={item.href}
                                decoration={false}
                                key={item.name}
                              >
                                {item.name}
                              </Link>
                            ))}
                            <AriaButton
                              onPress={() => {
                                setIsShowingUserMenu(false);
                                signOut();
                              }}
                            >
                              <Text className="hover:text-brand-primary">
                                Sign out
                              </Text>
                            </AriaButton>
                          </div>
                        </Popover.Content>
                      </Popover>
                    </div>
                    <div
                      className={
                        isAuthenticated === undefined || isAuthenticated
                          ? "hidden"
                          : "flex items-center justify-center space-x-3"
                      }
                    >
                      <Link
                        className="hidden lg:flex"
                        href="/signup"
                        decoration={false}
                      >
                        {t("Sign-up")}
                      </Link>
                      <Link
                        className="hidden lg:flex hover:!text-black"
                        href="/signin"
                        decoration={false}
                      >
                        <Button className="hidden lg:flex" size="xs">
                          {t("Log-In")}
                        </Button>
                      </Link>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>

          <Disclosure.Panel className="lg:hidden w-full">
            <div className="space-y-1 pb-3 pt-2">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current ? "bg-primaryLight" : "",
                    "block px-3 py-2 text-center text-sm"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>

            <div
              className={`w-full flex flex-col items-center justify-center gap-y-3 ${
                isAuthenticated === undefined || isAuthenticated ? "hidden" : ""
              }`}
            >
              <Link decoration={false} href="/signup">
                <Button>Create an account a</Button>
              </Link>
              <Link href="/signin">{t("Log-In")}</Link>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
