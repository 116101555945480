import React from "react";
import { Avatar } from "ui-components";

import { useAuth } from "@/src/hooks/useAuth";

export interface IProfilePicture {
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxs";
}

export function ProfilePicture({ size = "md" }: IProfilePicture): JSX.Element {
  const { authData } = useAuth();
  return (
    <Avatar
      src={authData?.userTaxonomy?.image?.public_id}
      alt="your-profile-picture"
      size={size}
    />
  );
}
