import {
  LoginResponse,
  OrganizationUserRoleResponse,
  UserTaxonomyEntity,
} from "../hooks/api/generated";

export function isOrganizationAdmin(
  taxonomy: UserTaxonomyEntity | undefined,
  organizationIdOrSlug: string | number
): boolean {
  return (
    (taxonomy?.organization?.slug === organizationIdOrSlug ||
      taxonomy?.organization?.id === Number(organizationIdOrSlug)) &&
    !!taxonomy?.organization?.organizationAdmins.length
  );
}

export function isUserAnOrganizationAdmin(
  taxonomy: UserTaxonomyEntity | undefined
): boolean {
  return !!taxonomy?.organization?.organizationAdmins.length;
}

export function hasPermission(
  roles: OrganizationUserRoleResponse[] | undefined,
  role: string
): boolean {
  return !!roles?.find((item) => item.role === role && item.hasAccess);
}

export function isOrganizationMember(
  authData: LoginResponse | undefined,
  organizationIdOrSlug: number | string
): boolean {
  return (
    authData?.userTaxonomy?.organization?.id === Number(organizationIdOrSlug) ||
    authData?.userTaxonomy?.organization?.slug === organizationIdOrSlug
  );
}
