"use client";

import {
  CopyrightIcon,
  FacebookIcon,
  LinkedinIcon,
  MailIcon,
  MyGworkLogoIcon,
  MyGworkLogoMobileIcon,
  YoutubeIcon,
} from "icons";
import NextLink from "next/link";
import { useTranslation } from "react-i18next";
import { Link, Text, LinkButton, ImageWrapper } from "ui-components";

import { NAVIGATION_SECTIONS, SectionName } from "@/src/constants/Footer";
import { useAuth } from "@/src/hooks/useAuth";

function Footer(): JSX.Element {
  const { t } = useTranslation("footer");
  const { isAuthenticated } = useAuth();

  return (
    <footer aria-labelledby="footer-heading">
      <div
        className={`flex flex-col-reverse lg:flex-col items-center gap-y-5 lg:gap-y-2.5 mb-10 lg:mb-10 ${
          isAuthenticated === undefined || isAuthenticated ? "hidden" : "flex"
        }`}
      >
        <div className="relative w-full h-[300px] mt-5">
          <ImageWrapper
            fill
            className="object-cover"
            src="https://res.cloudinary.com/dv6hrcluu/image/upload/v1714983418/web-app-images/home-page-footer.png"
            alt="download-on-the-apple-store"
            isCLDImage
          />
        </div>
      </div>
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="max-w-[1440px] mx-auto px-5 lg:px-20 lg:py-10 w-full">
        {/* Mobile Only */}
        <div className="flex flex-col lg:hidden gap-y-10 my-10">
          {/* GetMyGworkApp */}
          <GetMyGworkApp />
          {/* GetInTouch */}
          <GetInTouch />
          {/* StayInLoop */}
          <StayInLoop />
        </div>
        <div className="flex items-start justify-between gap-x-[15px] lg:gap-x-16">
          <div className="hidden lg:flex">
            <Link href="/">
              <MyGworkLogoIcon className="w-28" />
            </Link>
          </div>
          {Object.entries(NAVIGATION_SECTIONS).map(([key, values]) => (
            <div key={key} className="flex flex-col gap-y-1 lg:gap-y-2.5">
              <h3 className="text-[1rem] lg:text-lg text-brand-blue font-semibold mb-2.5 whitespace-nowrap">
                {t(`sections.${key as SectionName}`)}
              </h3>
              {values.map((value) => (
                <Link
                  key={value.name}
                  href={value.href}
                  decoration={false}
                  fontSize="base"
                >
                  {t(`section_navigation_items.${value.name}`)}
                </Link>
              ))}
            </div>
          ))}
          {/* Desktop Only */}
          <div className="hidden lg:block">
            {/* GetMyGworkApp */}
            <GetMyGworkApp />
          </div>
          <div className="hidden lg:flex lg:flex-col gap-y-5">
            {/* GetInTouch */}
            <GetInTouch />
            {/* StayInLoop */}
            <StayInLoop />
          </div>
        </div>
        <div className="w-full flex flex-col items-center justify-center lg:items-start">
          <MyGworkLogoMobileIcon className="block lg:hidden w-28 mb-2.5" />
          <Text
            variant="small"
            className="flex items-center gap-x-[5px] mt-0 lg:-mt-5"
          >
            <CopyrightIcon className="w-[14px] h-[14px]" />
            &nbsp;{t("all_rights_reserved", { year: new Date().getFullYear() })}
          </Text>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

function GetMyGworkApp(): JSX.Element {
  const { t } = useTranslation("footer");

  return (
    <div className="flex flex-col items-center justify-center lg:items-start gap-y-5">
      <Text variant="large" intent="secondary" className="lg:text-brand-blue">
        {t("get_the_mygwork_app")}
      </Text>
      <NextLink
        href="https://apps.apple.com/us/app/mygwork/id947603126"
        target="_blank"
      >
        <ImageWrapper
          width={182}
          height={80}
          src="https://res.cloudinary.com/dv6hrcluu/image/upload/v1709553689/web-app-images/AppStore.png"
          alt="download-on-the-apple-store"
          isCLDImage
        />
      </NextLink>
      <NextLink
        href="https://play.google.com/store/apps/details?id=com.mygwork.mygwork2"
        target="_blank"
      >
        <ImageWrapper
          width={182}
          height={80}
          src="https://res.cloudinary.com/dv6hrcluu/image/upload/v1731603518/web-app-images/google-play-download_sdtjli.png"
          alt="download-on-the-apple-store"
        />
      </NextLink>
    </div>
  );
}

function GetInTouch(): JSX.Element {
  const { t } = useTranslation("footer");

  return (
    <div className="flex flex-col items-center lg:items-start gap-y-2.5 lg:gap-y-5">
      <Text variant="large" intent="secondary" className="lg:text-brand-blue">
        {t("get_in_touch")}
      </Text>
      <div className="flex gap-x-3">
        <MailIcon className="w-6" />
        <NextLink href="mailto:support@mygwork.com">
          <Text>{t("email_us_at", { email: "support@mygwork.com" })}</Text>
        </NextLink>
      </div>
    </div>
  );
}

function StayInLoop(): JSX.Element {
  const { t } = useTranslation("footer");

  return (
    <div className="flex flex-col items-center lg:items-start gap-y-5">
      <Text variant="large" intent="secondary">
        {t("stay_in_the_loop")}
      </Text>
      <div className="flex gap-x-[1.875rem] lg:gap-x-2.5">
        <NextLink
          href="https://www.linkedin.com/company/mygwork"
          target="_blank"
        >
          <LinkedinIcon className="w-8" />
        </NextLink>
        <NextLink
          href="https://www.facebook.com/pages/myGwork/1412890678971219"
          target="_blank"
        >
          <FacebookIcon className="w-8" />
        </NextLink>
        <NextLink href="https://www.youtube.com/@myGwork" target="_blank">
          <YoutubeIcon className="w-8" />
        </NextLink>
        <NextLink href="https://instagram.com/myGwork" target="_blank">
          <ImageWrapper
            width={32}
            height={32}
            src="https://res.cloudinary.com/dv6hrcluu/image/upload/v1709553415/web-app-images/Instagram_Icon.png"
            alt="instagram"
          />
        </NextLink>
      </div>
      <LinkButton
        isAbsolute
        href="https://mygwork.us8.list-manage.com/subscribe?u=e472ddae19aec622957135ac4&id=7d7565ee67"
        target="_blank"
        intent="secondary"
      >
        {t("sign_up_to_our_newsletter")}
      </LinkButton>
    </div>
  );
}
