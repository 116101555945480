"use client";

import dayjs from "dayjs";
import { MyGworkLogoIcon } from "icons";
import { useRouter } from "next/navigation";
import React, { useState, useMemo } from "react";
import { Heading, Text } from "ui-components";

import {
  NotificationLogEntity,
  NotificationLogResponseEntityLinkType,
  useNotificationMarkNotificationAsClicked,
  NotificationLogResponseEntityType,
  NotificationLogEntityType,
} from "@/hooks/api/generated";
import { useCurrentLocale } from "@/hooks/useCurrentLocale";
import { ProfileTabsEnum } from "@/src/@types/Profile";

interface INotificationItemProps {
  notification: NotificationLogEntity;
}

export default function NotificationItem({
  notification: initialNotification,
}: INotificationItemProps): JSX.Element {
  const { mutate: markNotificationAsClicked } =
    useNotificationMarkNotificationAsClicked();
  const router = useRouter();
  const [notification, setNotification] = useState(initialNotification);
  const currentLocale = useCurrentLocale();

  const isSpecificNotificationType = useMemo(() => {
    const specificNotificationTypes = [
      NotificationLogResponseEntityType.Friend_Request,
      NotificationLogResponseEntityType.Friend_Request_Accepted,
      NotificationLogResponseEntityType.New_Message,
      NotificationLogResponseEntityType.Accepted_Organization_Invitation,
      NotificationLogResponseEntityType.Rejected_Join_Organization_Request,
    ] as NotificationLogEntityType[];

    return specificNotificationTypes.includes(notification.type);
  }, [notification.type]);

  const handleNotificationClick = (): void => {
    let url: string | null = null;

    switch (notification.type) {
      case NotificationLogResponseEntityType.Push:
        switch (notification.linkType) {
          case NotificationLogResponseEntityLinkType.External_URL:
            if (notification.externalUrl) {
              window.open(notification.externalUrl);
              return;
            }
            break;
          case NotificationLogResponseEntityLinkType.Article:
            url = `/news/${notification.postSlug}`;
            break;
          case NotificationLogResponseEntityLinkType.Event:
            url = `/events/${notification.postSlug}`;
            break;
          default:
            return;
        }
        break;

      case NotificationLogResponseEntityType.Friend_Request:
        url = `/${currentLocale}/profile/${ProfileTabsEnum.MY_CONNECTIONS}`;
        break;

      case NotificationLogResponseEntityType.Friend_Request_Accepted:
      case NotificationLogResponseEntityType.Accepted_Organization_Invitation:
      case NotificationLogResponseEntityType.Rejected_Join_Organization_Request:
        url = `/${currentLocale}/members/${notification?.sentBy?.slug}`;
        break;

      case NotificationLogResponseEntityType.New_Message:
        url = `/${currentLocale}/profile/${ProfileTabsEnum.MY_MESSAGES}?recipient=${notification?.sentBy?.id}`;
        break;

      case NotificationLogResponseEntityType.Password_Changed:
        url = `/${currentLocale}/profile/${ProfileTabsEnum.SETTINGS}`;
        break;

      default:
        return;
    }

    if (url) {
      router.push(url);
    }

    setNotification((prev) => ({
      ...prev,
      interactions: {
        ...prev.interactions,
        clickedAt: dayjs().format("MMM D, YYYY"),
      },
    }));
    markNotificationAsClicked({ notificationId: notification.id.toString() });
  };

  return (
    <div
      role="button"
      tabIndex={0}
      key={notification.id}
      className={`flex items-start border-b last:border-b-0 hover:bg-gray-100 hover:text-gray-900 cursor-pointer ${
        !notification.interactions ||
        notification.interactions.clickedAt === null
          ? "bg-[#EB773D33]"
          : ""
      }`}
      onClick={() => handleNotificationClick()}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          handleNotificationClick();
        }
      }}
    >
      <div className="p-4">
        {isSpecificNotificationType ? (
          <div className="w-[55px] relative !rounded-full !overflow-hidden">
            <img
              src={
                notification.sentBy?.userTaxonomy?.image?.name ||
                "/user-image-placeholder.webp"
              }
              alt={`${notification.sentBy.first_name} ${notification.sentBy.last_name}`}
            />
          </div>
        ) : (
          <MyGworkLogoIcon className="hidden lg:block w-[55px] h-[87px]" />
        )}
      </div>
      <div className="flex-grow pt-2 pb-2">
        <Heading variant="h6">{notification.title}</Heading>
        <div className="mt-1">
          <Text className="text-gray-600 mt-1 text-sm">
            {notification.body}
          </Text>
        </div>
        <div className="mt-2">
          <Text className="text-gray-500 mt-2 text-xs">
            {dayjs(notification.createdAt).format("MMM D, YYYY")}
          </Text>
        </div>
      </div>
      <div className="p-4">
        <div
          className={`w-[0.6rem] h-[0.6rem] bg-orange-500 rounded-full mt-8 ${
            notification.interactions &&
            notification.interactions.clickedAt !== null
              ? "hidden"
              : ""
          }`}
        />
      </div>
    </div>
  );
}
