import React, { useEffect, useState } from "react";
import { Dialog } from "react-aria-components";
import { ImageWrapper, Spinner, Text } from "ui-components";

import NotificationItem from "@/components/layout/NotificationItem";
import {
  NotificationGetUserNotificationsMutationResult,
  NotificationLogEntity,
  useNotificationClearUserNotifications,
  useNotificationGetUserNotifications,
} from "@/hooks/api/generated";

export function NotificationListing(): JSX.Element {
  const {
    mutateAsync: fetchNotifications,
    isPending: isFetchNotificationsPending,
  } = useNotificationGetUserNotifications();
  const { mutate: clearUserNotifications } =
    useNotificationClearUserNotifications();
  const [notificationsData, setNotificationsData] =
    useState<NotificationGetUserNotificationsMutationResult>([]);

  const handleClearNotificationsClick = async (): Promise<void> => {
    clearUserNotifications();
    setNotificationsData([]);
  };

  const fetchNotificationsData = async (): Promise<void> => {
    const getNotificationsData = await fetchNotifications();
    setNotificationsData(getNotificationsData);
  };

  useEffect(() => {
    fetchNotificationsData();
  }, []);

  if (isFetchNotificationsPending) {
    return (
      <div className="flex flex-1 flex-col items-center justify-center w-full p-10  max-h-[80vh] gap-4 overflow-y-auto ">
        <Spinner />
      </div>
    );
  }

  return (
    <Dialog className="outline-none text-gray-700 mx-auto">
      {notificationsData.length === 0 ? (
        <div className="text-center mb-10">
          <div className="flex justify-center items-center my-5">
            <ImageWrapper
              src="https://res.cloudinary.com/dv6hrcluu/image/upload/v1730718925/web-app-images/no-notifications_mipuuo.png"
              width={110}
              height={203}
              alt="No notifications available"
              className="my-5"
            />
          </div>
          <div className="mt-1">
            <Text className="text-sm">
              There are no new notifications for now
            </Text>
          </div>
          <div className="mt-1">
            <Text className="text-sm">Keep watching this space!</Text>
          </div>
        </div>
      ) : (
        <div className="flex flex-col">
          <div className="flex justify-items-start border-b">
            <button
              className="text-sm underline py-2 px-4"
              onClick={() => handleClearNotificationsClick()}
              aria-label="Clear all notifications"
            >
              Clear all
            </button>
          </div>
          <div className="overflow-y-auto h-[35rem]">
            {notificationsData.map((notification: NotificationLogEntity) => (
              <NotificationItem notification={notification} />
            ))}
          </div>
        </div>
      )}
    </Dialog>
  );
}
