import { usePathname, useRouter } from "next/navigation";

import { locales, defaultLocale } from "../../i18nConfig";

interface IQueryParam {
  key: string;
  value: string;
  shouldResetPage?: boolean;
  scroll?: boolean;
}
interface IQueryParams {
  key: string;
  value: string;
}

interface IUseNavigate {
  push: (path: string) => void;
  pushQueryParam: (queryParams: IQueryParam) => void;
  pushQueryParams: (
    queryParams: IQueryParams[],
    resetPageNumber?: boolean,
    scroll?: boolean
  ) => void;
  clearQueryParams: () => void;
}

export function useNavigate(): IUseNavigate {
  const pathname = usePathname();
  const router = useRouter();

  // eslint-disable-next-line prefer-destructuring
  const currentLocal = pathname?.split("/")[1] ?? "";

  const isCurrentLocal = locales.includes(currentLocal);

  const selectLocal = isCurrentLocal ? currentLocal : defaultLocale;

  const handlePush = (path: string): void => {
    router.push(`/${selectLocal}/${path}`);
  };

  const handlePushQueryParam = (newQueryParam: IQueryParam): void => {
    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set(newQueryParam.key, newQueryParam.value);
    if (newQueryParam.shouldResetPage) {
      searchParams.set("page", "1");
    }

    const newQueryParamsString = searchParams.toString();

    router.push(`${window.location.pathname}?${newQueryParamsString}`, {
      scroll: newQueryParam.scroll,
    });
  };

  const handlePushQueryParams = (
    newQueryParams: IQueryParams[],
    resetPageNumber?: boolean
  ): void => {
    const searchParams = new URLSearchParams(window.location.search);
    newQueryParams.forEach((param) => searchParams.set(param.key, param.value));
    if (resetPageNumber) {
      searchParams.set("page", "1");
    }
    const newQueryParamsString = searchParams.toString();

    router.push(`${window.location.pathname}?${newQueryParamsString}`);
  };

  const clearQueryParams = (): void => {
    router.push(`${window.location.pathname}`);
  };
  return {
    push: handlePush,
    pushQueryParams: handlePushQueryParams,
    pushQueryParam: handlePushQueryParam,
    clearQueryParams,
  };
}
